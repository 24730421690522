import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/dashboard";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import Login from "../authentication/login/login";
import ForgotPassword from "../authentication/login/forgot-password";
import RegistrationComplain from "../registration/registration-complain/registration-complain";
import Sample from "../sample/sample";
import DeferSemester from "../registration/defer-semester/defer-semester";
import DashBoard from "../dashboard/dashboard";
import Registration from "../registration/registration/registration";
import MyTimeTable from "../timetable/my-timetable";
import RegisteredModule from "../registration/register-module/register-module";
import PrintModule from "../registration/register-module/print-module";
import ModuleEvaluation from "../registration/module-evaluation/module-evaluation";
import StaffRatings from "../registration/module-evaluation/staff-ratings";
import Progression from "../progrssion/progression-complain";
import ChangeOfCourse from "../change-of-course/change-of-course";
import SemesterResult from "../student-result/semester-result";
import UploadPayment from "../payment/upload-payment";
import PaymentRollOver from "../payment/payment-roll-over";
import ExamTimetable from "../timetable/exam-timetable";
import ResultComplain from "../student-result/result-complain";
import Thesis from "../my-thesis/my-thesis";
import Attendance from "../attendance/attendance";
import Assessment from "../assessment/assessment";
import DigitalTransformation from "../digital-transformation/digital-transformation";
// import GroupTimetable from "../timetable/group-timetable";
import Accept from "../change-of-course/accept";
import Reject from "../change-of-course/reject";
import InterimTranscript from "../interim-transcript/interim-transcript";
import MarkMyAttendance from "../attendance/mark-my-attendance";
import ServiceDesk from "../portals/service-desk/service-desk";
import Hostel from "../portals/hostel/hostel";
import ViewRoom from "../portals/hostel/view-room";
import ReserveRoom from "../portals/hostel/reserve-room";
import Internship from "../portals/internship/internship";
import Transcript from "../portals/transcript/transcript";
import SemesterRegistration from "../registration/add-drop/semester-registration";
import LoginFromStaff from "../authentication/login/login-from-staff";

export default function PageRoutes() {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            id="kt_content_container"
            className="container-custom container-xxl d-flex flex-column-fluid"
          >
            <div
              className="content d-flex flex-row flex-row-fluid"
              id="kt_content"
            >
              <Routes>
                {/* Dashboard Redirect */}
                <Route exact path="/" element={<Dashboard />} />
                {/* General */}
                <Route path="/login" element={<Login />} />
                <Route path="/sample" element={<Sample />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/login-from-staff/:id" element={<LoginFromStaff/>}/>
                {/* Regsitration Route */}
                <Route
                  path="/registration/registration-complain"
                  element={<RegistrationComplain />}
                />
                <Route
                  path="/registration/registration"
                  element={<Registration />}
                />
                <Route
                  path="/registration/registered-modules"
                  element={<RegisteredModule />}
                />
                <Route
                  path="/registration/print-module"
                  element={<PrintModule />}
                />
                <Route
                  path="/registration/add-drop"
                  element={<SemesterRegistration />}
                />
                {/* <Route
                  path="/registration/add-drop"
                  element={<AddDrop />}
                /> */}
                <Route
                  path="/student/progression-complain"
                  element={<Progression />}
                />
                <Route
                  path="/student/change-of-course"
                  element={<ChangeOfCourse />}
                />
                <Route
                  path="/registration/module-evaluation"
                  element={<ModuleEvaluation />}
                />
                 <Route
                  path="/registration/staff-ratings/:id"
                  element={<StaffRatings />}
                />
                 <Route
                  path="/student/upload-payment"
                  element={<UploadPayment />}
                />
                 <Route
                  path="/student/payment-roll-over"
                  element={<PaymentRollOver />}
                />
                <Route
                  path="/student/my-timetable"
                  element={<MyTimeTable />}
                />
                {/* <Route
                  path="/student/group-timetable"
                  element={<GroupTimetable />}
                /> */}
                <Route
                  path="/student/exam-timetable"
                  element={<ExamTimetable />}
                />
                 <Route
                  path="/student/defer-semester"
                  element={<DeferSemester />}
                />
                <Route
                  path="/student/semester-result"
                  element={<SemesterResult />}
                />
                <Route
                  path="/student/result-complain"
                  element={<ResultComplain />}
                />
                 <Route
                  path="/student/my-thesis"
                  element={<Thesis/>}
                />
                <Route
                  path="/student/attendance"
                  element={<Attendance/>}
                />
                 <Route
                  path="/student/mark-my-attendance"
                  element={<MarkMyAttendance/>}
                />
                <Route
                  path="/student/assessment"
                  element={<Assessment/>}
                />
                <Route path="/student/interim-transcript" element={<InterimTranscript/>}/>
                <Route
                  path="/student/digital-transformation"
                  element={<DigitalTransformation/>}
                />


                <Route
                  path="/student/service-desk"
                  element={<ServiceDesk />}
                />

                <Route
                  path="/student/hostel"
                  element={<Hostel />}
                />
                
                <Route
                  path="/student/hostel/view-room"
                  element={<ViewRoom />}
                />
                 <Route
                  path="/student/hostel/reserve-room"
                  element={<ReserveRoom />}
                />

<Route
                  path="/student/internship"
                  element={<Internship />}
                />
                <Route
                  path="/student/transcript"
                  element={<Transcript />}
                />

                <Route path={'/student/dashboard'} element={<DashBoard/>} />

                <Route path="/student/change-of-course/approve/:id" element={<Accept/>}/>
                <Route path="/student/change-of-course/reject/:id" element={<Reject/>}/>                
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
